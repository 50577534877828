import React, { createContext, useState, useMemo, useContext, useEffect, useRef, startTransition } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import io from 'socket.io-client';
import networkInterceptor from 'src/functions/networkInterceptor';
import { serverAddress, testEndPoints, loginEndPoints, patientsEndPoints, productsEndpoints, servicesEndpoints, staffEndPoints, } from 'src/api/endpoints';
import useFormattingFunctions from "src/functions/useFormattingFunctions";

const GlobalsContext = createContext();

export function RedirectProvider(props) {
    
    const TOAST_ANIMATION_TIME = 12000;
    const TOAST_ANIMATION_TIME_MOBILE = 6000;
    const SOCKET_RECONNECTION_TIME = 3000;
    const DEFAULT_PATIENT_TEMPLATE_VARIABLES = [
        {variableName: 'nombre', propertyName: 'fullName'},
        {variableName: 'nombres completos', propertyName: 'fullName'},
        {variableName: 'mi centro odontologico', propertyName: null},
        {variableName: 'fecha', propertyName: null},
        {variableName: 'hora', propertyName: null},
    ];
    const PUBLIC_URLS = [
        '/home',
        '/new-profile'
    ];
    const DEFAULT_LOCAL_SETTINGS = {
        whatsappType: 'installed',
    };
    const DEFAULT_SUCCESS_MESSAGE = 'Cambios registrados';
    const DEFAULT_ERROR_MESSAGE = 'Ups! Hubo un error';
    const DEFAULT_REMINDERS = [
        {
            counterValue: 7,
            message: 'Hola --nombre--, ya está lista tu cita para --fecha-- con nosotros en --mi centro odontologico--, te esperamos',
            active: true,
            sent: false 
        },
        {
            counterValue: 1,
            message: 'Hola --nombre--, recuerda que --fecha-- tienes una cita con --mi centro odontologico--',
            active: true,
            sent: false 
        },
        {
            counterValue: 6,
            message: 'Hola --nombre--, --fecha-- te esperamos para tu cita a --hora-- en --mi centro odontologico--. ¿Podrías confirmar tu asistencia?',
            active: true,
            sent: false 
        },
    ];

    const { sortBy } = useFormattingFunctions();

    const [ jwt, SetJwt ] = useState(localStorage.getItem('storedJWT') ? JSON.parse(localStorage.getItem('storedJWT')) : sessionStorage.getItem('storedJWT') ? JSON.parse(sessionStorage.getItem('storedJWT')) : null);
    const [ selectedPatient, SetSelectedPatient ] = useState();
    const [ showContextMenu, SetShowContextMenu ] = useState(false);
    const [ showToast, SetShowToast ] = useState(false);
    const [ showGlobalConfirmationModal, SetShowGlobalConfirmationModal ] = useState(false);
    const [ businessUserName, SetBusinessUserName ] = useState(localStorage.getItem('businessUserName') ? JSON.parse(localStorage.getItem('businessUserName')): '');
    const [ showOptionsMenu, SetShowOptionsMenu ] = useState(false);
    const [ isOnline, SetIsOnline ] = useState(navigator.onLine);
    const [ myData, SetMyData ] = useState(localStorage.getItem('myData') ? JSON.parse(localStorage.getItem('myData')) : undefined);
    const [ appointmentsByMonth, SetAppointmentsByMonth ] = useState({});
    const [ appointmentsByMonthInitialLoad, SetAppointmentsByMonthInitialLoad ] = useState(false);
    const [ doctorsList, SetDoctorsList ] = useState([]);
    const [ selectedDate, SetSelectedDate ] = useState(new Date());
    const [ loadFirstMonthAppointments, SetLoadFirstMonthAppointments ] = useState(false);
    const [ isGlobalTransitionPending, SetIsGlobalTransitionPending ] = useState(false);
    const [ modalWithAnimationsOptions, SetModalWithAnimationsOptions ] = useState({});

    // refs
    const isAppInstalled = useRef(isRunningInAppMode());
    const changesMadeRef = useRef(false);
    const isMobileRef = useRef(isMobile());
    const jwtRef = useRef(localStorage.getItem('storedJWT') ? JSON.parse(localStorage.getItem('storedJWT')) : sessionStorage.getItem('storedJWT') ? JSON.parse(sessionStorage.getItem('storedJWT')) : null);
    const tokenExpirationRef = useRef(0);
    const appointmentsByMonthRequestedMonths_ref = useRef([]);
    
    const [ contextMenuCoordinates, SetContextMenuCoordinates ] = useState({
        x: 0,
        y: 0
    });
    const [ contextMenuOptions, SetContextMenuOptions ] = useState([]);
    const [showModal, SetShowModal] = useState(false);
    
    const storedJwt = localStorage.getItem('storedJWT') || sessionStorage.getItem('storedJWT');

    if(!jwt && storedJwt && storedJwt !== 'undefined') SetJwt(JSON.parse(storedJwt));

    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = location.pathname;

    const businessSocketRoom = useMemo(() => io(`${serverAddress}/business-socket`, {
        transports: ['websocket']
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [jwt]);

    const fetchData = async (url, options) => {
        try {
            return await networkInterceptor(url, options)
        } catch (err) {
            const URL_EXCEPTIONS = [
                'unregister-push-subscription'
            ];
            if((err.message === 'invalid token' || err.message === 'jwt malformed') && !URL_EXCEPTIONS.some(urlException => {
                console.log(urlException)
                return url.includes(urlException)})) {
                redirect()
                throw new Error('invalid token')
            } else {
                throw new Error(err.message);
            }
        }
    }

    const getQueryParameter = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    }

    const removeParams = (param) => {
        const path = location.pathname;
        const params = new URLSearchParams(location.search);

        params.delete(param);

        startTransition(() => {
            navigate(`${path}?${params.toString()}`, { replace: true });
        });
    }

    const navigateTo = (param, value) => {
        const path = location.pathname;
        const params = new URLSearchParams(location.search);

        startTransition(() => {
            if(!param) {
                params.forEach((_, key) => {
                    params.delete(key);
                });
                navigate(path);
            } else {
                params.set(param, value);
                navigate(`${path}?${params.toString()}`);
            }
        });
    };

    const getMonthlyAppointments = async (startDate, endDate, signal) => {
        try {
            const data = await fetchData(patientsEndPoints.getMonthlyAppointments, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${jwtRef.current}`                
                }, 
                signal,
                body: JSON.stringify({
                    startDate, endDate
                })
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getDoctors = async () => {
        try {
            const response = await fetchData(`${staffEndPoints.getDoctors}`, {
                headers: {
                    'Authorization': `Bearer: ${jwtRef.current}`
                }
            })
            return response;
        } catch (err) {
            console.error(err?.message)
            // throw new Error(err.message)
        }
    
    }

    const redirect = () => {
        SetShowModal(true)
    }

    const resetModal = () => {
        SetShowModal(false);
        logout();
    }

    const setReceiveReminderMessages = async (status, token) => {
        try {
            let subscription = false;
            const id = getMachineId();
            if(status) {
                const registration = await navigator.serviceWorker.ready;
                // Use the PushManager to get the user's subscription to the push service.
                subscription =  await registration.pushManager.getSubscription();
                // If a subscription was found, return it.
                if(!subscription) subscription = await subscribeWebWorker(registration, token ? token : jwt);
            }
            const data = await fetchData(staffEndPoints.setReceiveReminderMessages, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token ? token : jwt}`                
                }, 
                body: JSON.stringify({subscription, id})
            })
            return data;
        } catch (err) {
            console.error(err);
            throw new Error(err.message)
        }
    }
 
    const setToken = async ({token, expiration, rememberMe, businessUsername, staff}) => {
        if(rememberMe) localStorage.setItem('storedJWT', JSON.stringify(token));
        else sessionStorage.setItem('storedJWT', JSON.stringify(token));
        tokenExpirationRef.current = expiration;
        SetBusinessUserName(businessUsername.toLowerCase().trim());
        SetJwt(token);
        jwtRef.current = token;
        SetLoadFirstMonthAppointments(true);
        checkNotificationPermission(token);
        localStorage.setItem('myData', JSON.stringify(staff));
        SetMyData(staff);
        if(staff.business.remindersSubscriptions[getMachineId()]) {
            setReceiveReminderMessages(true, token); //Update reminders subscription
        }

        return true;
    };

    const updateMyData = (_myData) => {
        const localSettings = JSON.parse(localStorage.getItem('localSettings'));
        const myLocaSettings = localSettings[myData.business._id];
        const myLocalData = myLocaSettings[myData._id];
        myLocalData.myData = _myData;

        SetMyData({..._myData});
        localStorage.setItem('myData', JSON.stringify(_myData));
        localStorage.setItem('localSettings', JSON.stringify(localSettings));
    }

    const logout = async () => {
        try {
            localStorage.removeItem('storedJWT');
            jwtRef.current = null;
            sessionStorage.clear();
            SetMyData(null);
            SetJwt(null);
            SetShowOptionsMenu(false);
            SetLoadFirstMonthAppointments(false);
            changesMadeRef.current = false;
            navigate("/");
            // const registration = await navigator.serviceWorker.ready;
            // let subscription =  await registration.pushManager.getSubscription();
            if(myData?.business?._id) fetchData(`${serverAddress}/unregister-push-subscription`, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    business: myData?.business?._id,
                    machineId: getMachineId()
                }),
            });
        } catch (err) {
            SetShowModal(false);
            console.error(err);
        }
    };

    const refreshTokenAPI = async (jwt) => {
        const data = await fetchData(loginEndPoints.refreshToken, {
            method: "GET",
            headers: {
              'Authorization': `Bearer: ${jwtRef.current}`
            }, 
          })
        return data;
    }

    const fireToast = (toastSettings) => {
        SetShowToast(false);
        setTimeout(() => SetShowToast(toastSettings), 100);
    };

    const subscribeWebWorker = async (registration, jwtToken) => {
        // Get the server's public key

        try {
            console.log('registration:')
            console.log(registration)
            const vapidPublicKey = await fetchData(`${serverAddress}/vapid-public-key`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer: ${jwtToken}`
                }, 
            });

            console.log('vapidPublicKey:')
            console.log(vapidPublicKey)
            
            // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
            function urlBase64ToUint8Array(base64String) {
                var padding = '='.repeat((4 - base64String.length % 4) % 4);
                var base64 = (base64String + padding)
                    // .replace(/\-/g, '+')
                    .replace(/-/g, '+')
                    .replace(/_/g, '/');
                var rawData = window.atob(base64);
                var outputArray = new Uint8Array(rawData.length);
                
                for (var i = 0; i < rawData.length; ++i) {
                    outputArray[i] = rawData.charCodeAt(i);
                }
                return outputArray;
            }
            const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

            // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
            // send notifications that don't have a visible effect for the user).
            return registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidKey
            });
        } catch (err) {
            console.error(err);
            throw new Error(err.message);
        }
        
    }

    const initiatePushSubscription = async (jwtToken) => {
        try {
            const registration = await navigator.serviceWorker.ready;
            // Use the PushManager to get the user's subscription to the push service.
            let subscription =  await registration.pushManager.getSubscription();
            // If a subscription was found, return it.
            if (!subscription) subscription = await subscribeWebWorker(registration, jwtToken);
    
            // Send the subscription details to the server using the Fetch API.
            fetchData(`${serverAddress}/register-push-subscription`, {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer: ${jwtToken}`
                },
                body: JSON.stringify({
                    machineId: getMachineId(),
                    subscription: subscription
                }),
            });
        } catch (err) {
            console.error('initiatePushSubscription error')
            console.error(err);
        }
    }

    function isRunningInAppMode () {
        let displayMode = 'browser tab';
        if (window.matchMedia('(display-mode: standalone)').matches) {
            displayMode = 'standalone';
        }
        return displayMode === 'browser tab' ? false : true;
    }

    const checkUnsavedChangesBeforeContinue = (cb, ...args) => {
        SetShowContextMenu(false);
        if(changesMadeRef.current) {
            SetShowGlobalConfirmationModal({
                title: 'Cambios sin guardar',
                text: 'Si continua, todos sus cambios serán descartados',
                close: 'Cancelar',
                type: 'warning',
                acceptText: 'Descartar cambios',
                onAccept: () => {
                    changesMadeRef.current = false;
                    cb(...args);
                },
            })
        } else cb(...args);
    }

    function isMobile() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const touchEnabled = true;
        // const touchEnabled = isTouchEnabled();
        const vertical = height > width;

        return touchEnabled && vertical;

        // function isTouchEnabled() {
        //     return ( 'ontouchstart' in window ) || 
        //            ( navigator.maxTouchPoints > 0 ) ||
        //            ( navigator.msMaxTouchPoints > 0 );
        // }
    }

    async function checkNotificationPermission(token) {
        // const options = {
        //     type: 'danger',
        //     title: 'Permisos necesarios',
        //     text: 'Otorgar permisos para poder mostrar notificaciones'
        // }
        if (Notification.permission === 'granted') {
            // Push notifications are allowed
            console.log('Push notifications are allowed');
            initiatePushSubscription(token);
        } else if (Notification.permission === 'denied') {
            // Push notifications are denied
            console.log('Push notifications are denied');
            // setTimeout(() => fireToast(options), 1200);
        } else {
            // Push notifications are not yet granted or denied
            console.log('Push notifications have not been granted or denied yet');
            const result = await Notification.requestPermission();
            if (result === "granted") {
                console.log('Push notifications permissions allowed');
                initiatePushSubscription(token);
            } else {
                // fireToast(options);
                console.log('Push notifications permissions not allowed');
            }
        }
    }

    const disableOverScroll = () => {
        document.querySelector('body').classList.add('no-overscroll');
    };

    const enableOverScroll = () => {
        document.querySelector('body').classList.remove('no-overscroll');
    };

    const fireWhatsapp = (phoneNumber, message) => {
        navigate('/reminders');
        
        // const username = JSON.parse(localStorage.getItem('username'));
        // const whatsappType = JSON.parse(localStorage.getItem('localSettings'))?.[username]?.whatsappType || 'installed';
        // let whatsappLink =
        //     whatsappType === 'installed'
        //         ? `whatsapp://send?phone=51${phoneNumber}&text=${encodeURI(message)}&source&data`
        //     : whatsappType === 'web'
        //         ? `https://web.whatsapp.com/send?phone=51${phoneNumber}&text=${encodeURI(message)}`
        //         : `https://wa.me/51${phoneNumber}?text=${encodeURI(message)}`;
        // const link = document.createElement('a');
        // link.href = whatsappLink;
        // link.target = "_blank"; // Open link in a new tab
        // link.click();
    }

    function getMachineId() {
    
        let machineId = localStorage.getItem('MachineId');
        
        if (!machineId) {
            machineId = crypto.randomUUID();
            localStorage.setItem('MachineId', machineId);
        }
    
        return machineId;
    }

    const markReminderAsSent = async (appointmentId, index) => {
        try {
            const data = await fetchData(patientsEndPoints.markReminderAsSent, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${jwtRef.current}`      
                }, 
                body: JSON.stringify({appointmentId, index})
            })
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    useEffect(() => {
        if(!showOptionsMenu) enableOverScroll();
        else disableOverScroll();
    }, [showOptionsMenu])

    useEffect(()=> {
        // if(!isAppInstalled.current && !PUBLIC_URLS.includes(currentUrl)) {
        //     window.location.href = window.location.origin + '/#/home'
        //     return
        // };
        if(!jwt) return;
        // if(PUBLIC_URLS.includes(currentUrl)) return;

        const refreshTokenTimer = setTimeout(() => {
            (async () => {
                try {
                    const { newToken, expiration, businessName, businessUsername, staff } = await refreshTokenAPI(jwt);
                    let rememberMe = false;
                    if(localStorage.getItem('storedJWT') && localStorage.getItem('storedJWT') !== 'undefined') rememberMe = true;
                    setToken({token: newToken, expiration, businessName, rememberMe, staff, businessUsername});
                } catch (err) {
                    clearTimeout(refreshTokenTimer);
                    logout();
                    if(currentUrl !== '/') redirect();
                }
            })()
        }, tokenExpirationRef.current - 60000); //Request jwt token n seconds before it expires

        if(currentUrl === '/') navigate("/calendar-page");

        return () => {
            clearTimeout(refreshTokenTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt]);

    useEffect(() => {
        if(!jwtRef.current) return;

        businessSocketRoom.emit('joinRoom', jwtRef.current);
        businessSocketRoom.on("connect_error", (err) => {
            console.log(`connect_error due to: ${err.message}`);
        });
        
        businessSocketRoom.on("err", (err) => console.error(err));
        businessSocketRoom.on("success", (res) => console.log(res));
        businessSocketRoom.on("disconnect", (res) => {
            console.log("business socket disconnected");
            if(jwtRef.current) setTimeout(() => {
                console.log(businessSocketRoom.emit('joinRoom', jwtRef.current))
            }, SOCKET_RECONNECTION_TIME);
        });
        businessSocketRoom.on("new-appointment", async (newAppointment) => {
            console.log('New appointment received');
            const appointmentMonth = new Date(newAppointment.time).getMonth();
            const appointmentYear = new Date(newAppointment.time).getFullYear();
            const key = `${appointmentYear}-${appointmentMonth}`;
            if(!appointmentsByMonth[key]) return;
            const cachedAppointmentsByMonth = await retrieveJsonCache('appointmentsByMonth') || {};

            if(!cachedAppointmentsByMonth[key]) cachedAppointmentsByMonth[key] = [];
            cachedAppointmentsByMonth[key].push(newAppointment);
            cachedAppointmentsByMonth[key] = sortBy(cachedAppointmentsByMonth[key], 'time');
            
            SetAppointmentsByMonth(cachedAppointmentsByMonth);
            await storeJsonInCache('appointmentsByMonth', cachedAppointmentsByMonth);
        });
        businessSocketRoom.on("appointment-updated", async (updatedAppointment, oldDate) => {
            console.log('New appointment update received');
            oldDate = new Date(oldDate);
            const oldAppointmentMonth = oldDate.getMonth();
            const oldAppointmentYear = oldDate.getFullYear();
            const oldKey = `${oldAppointmentYear}-${oldAppointmentMonth}`;
            const appointmentMonth = new Date(updatedAppointment.time).getMonth();
            const appointmentYear = new Date(updatedAppointment.time).getFullYear();
            const newKey = `${appointmentYear}-${appointmentMonth}`;
            
            if(appointmentsByMonth[oldKey]) {
                const foundAppointmentIndex = appointmentsByMonth[oldKey].findIndex(appointment => appointment._id === updatedAppointment._id);
                if(foundAppointmentIndex > -1) {
                    appointmentsByMonth[oldKey].splice(foundAppointmentIndex, 1);
                };
            }
            if(appointmentsByMonth[newKey]) {
                appointmentsByMonth[newKey].push(updatedAppointment);
                appointmentsByMonth[newKey] = sortBy(appointmentsByMonth[newKey], 'time');
            }
            SetAppointmentsByMonth({...appointmentsByMonth});
            await storeJsonInCache('appointmentsByMonth', appointmentsByMonth);
        });

        businessSocketRoom.on("appointment-updated-status", async ({appointmentId, status, appointmentDate}) => {
            console.log('New appointment status updated received')
            const appointmentMonth = new Date(appointmentDate).getMonth();
            const appointmentYear = new Date(appointmentDate).getFullYear();
            const key = `${appointmentYear}-${appointmentMonth}`;
            if(!appointmentsByMonth[key]) return;
            const selectedAppointment = appointmentsByMonth[key].find(appointment => appointment._id === appointmentId);
            if(!selectedAppointment) return;
            selectedAppointment.status = status;

            SetAppointmentsByMonth({...appointmentsByMonth});
            await storeJsonInCache('appointmentsByMonth', appointmentsByMonth);
        });

        businessSocketRoom.on("appointment-deleted", async ({appointmentId, appointmentDate}) => {
            console.log('New appointment deleted received')
            const appointmentMonth = new Date(appointmentDate).getMonth();
            const appointmentYear = new Date(appointmentDate).getFullYear();
            const key = `${appointmentYear}-${appointmentMonth}`;
            if(!appointmentsByMonth[key]) return;
            const selectedAppointmentIndex = appointmentsByMonth[key].findIndex(appointment => appointment._id === appointmentId);
            if(selectedAppointmentIndex < 0) return;

            appointmentsByMonth[key].splice(selectedAppointmentIndex, 1);
            
            SetAppointmentsByMonth({...appointmentsByMonth});
            await storeJsonInCache('appointmentsByMonth', appointmentsByMonth);
        });

        return () => {
            businessSocketRoom.off('joinRoom');
            businessSocketRoom.off('connect_error');
            businessSocketRoom.off('err');
            businessSocketRoom.off('success');
            businessSocketRoom.off("disconnect");
            businessSocketRoom.off('new-appointment');
            businessSocketRoom.off('appointment-updated-status');
            businessSocketRoom.off('appointment-updated');
            businessSocketRoom.off('appointment-deleted');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentsByMonth, businessSocketRoom]);

    useEffect(() => {
        if(!loadFirstMonthAppointments || !jwtRef.current) return

        loadAppointmentsForFirstMonth();
        
        async function loadAppointmentsForFirstMonth() {
            const appointmentsSortedByMonth = {};
            const initialDate = new Date();
            const startDate = new Date(initialDate);
            const endDate = new Date(initialDate);

            startDate.setMonth(startDate.getMonth());
            startDate.setDate(1);
            startDate.setHours(0, 0, 0, 0);
            endDate.setMonth(endDate.getMonth());
            endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
            endDate.setHours(23, 59, 59, 999);

            const response = await getMonthlyAppointments(startDate, endDate);

            response.forEach(appointment => {
                const dateObj = new Date(appointment.time);
                const month = dateObj.getMonth();
                const year = dateObj.getFullYear();
                
                if(!appointmentsSortedByMonth[`${year}-${month}`]) appointmentsSortedByMonth[`${year}-${month}`] = [];
                appointmentsSortedByMonth[`${year}-${month}`].push(appointment);
            });
            if(!response.length) appointmentsSortedByMonth[`${initialDate.getFullYear()}-${initialDate.getMonth()}`] = [];

            Object.keys(appointmentsSortedByMonth).forEach(key => {
                const sorted = sortBy(appointmentsSortedByMonth[key], 'time');
                appointmentsByMonth[key] = sorted;
            });

            const cachedAppointmentsByMonth = await retrieveJsonCache('appointmentsByMonth') || {};
            appointmentsByMonthRequestedMonths_ref.current = [Object.keys(appointmentsSortedByMonth)[0]];
            
            SetAppointmentsByMonth({...cachedAppointmentsByMonth, ...appointmentsSortedByMonth});
            SetAppointmentsByMonthInitialLoad(true);
            await storeJsonInCache('appointmentsByMonth', {...cachedAppointmentsByMonth, ...appointmentsSortedByMonth});
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadFirstMonthAppointments]);

    useEffect(() => {
        const NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE = 3;
        const MONTHS_PROXIMITY_THRESHOLD = 2;
        
        const controller1 = new AbortController();
        const controller2 = new AbortController();
        const signal1 = controller1.signal;
        const signal2 = controller2.signal;

        const loadAppointments = async () => {
            if(!jwtRef.current || !appointmentsByMonthInitialLoad) return;

            try {
                const earliestMonthLoadedNumber = (() => {
                    if(appointmentsByMonthRequestedMonths_ref.current?.[0]) {
                        const yearMonth = appointmentsByMonthRequestedMonths_ref.current[0].split('-');
                        const year = yearMonth[0];
                        const month = yearMonth[1] < 10 ? `0${yearMonth[1]}` : yearMonth[1];
                        return Number(`${year}${month}`);
                    } else return Number(`${selectedDate.getFullYear()}${selectedDate.getMonth() < 10 ? `0${selectedDate.getMonth()}` : selectedDate.getMonth()}`); 
                })();
                const latestMonthLoadedNumber = (() => {
                    if(appointmentsByMonthRequestedMonths_ref.current?.[appointmentsByMonthRequestedMonths_ref.current?.length - 1]) {
                        const yearMonth = appointmentsByMonthRequestedMonths_ref.current[appointmentsByMonthRequestedMonths_ref.current.length - 1].split('-');
                        const year = yearMonth[0];
                        const month = yearMonth[1] < 10 ? `0${yearMonth[1]}` : yearMonth[1];
                        return Number(`${year}${month}`);
                    } else return Number(`${selectedDate.getFullYear()}${selectedDate.getMonth() < 10 ? `0${selectedDate.getMonth()}` : selectedDate.getMonth()}`); 
                })();
                
                const earliestMonthLoadedDate = new Date(earliestMonthLoadedNumber.toString().substring(0, 4), earliestMonthLoadedNumber.toString().substring(4, 6));
                const latestMonthLoadedDate = new Date(latestMonthLoadedNumber.toString().substring(0, 4), latestMonthLoadedNumber.toString().substring(4, 6));

                //if current selected month is MONTHS_PROXIMITY_THRESHOLD months away from earliest or latest loaded month,
                //load NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE more months in that direction in time (past or future months);

                const numberOfMonthsToEarliestLoadedMonth = calculateProximityInMonths(earliestMonthLoadedDate, selectedDate);
                const numberOfMonthsToLatestLoadedMonth = calculateProximityInMonths(selectedDate, latestMonthLoadedDate);

                const promisesArr = [];
                if(numberOfMonthsToEarliestLoadedMonth <= MONTHS_PROXIMITY_THRESHOLD) {
                    promisesArr.push(requestAppointments(earliestMonthLoadedDate, NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE * -1, signal1));
                }
                if(numberOfMonthsToLatestLoadedMonth <= MONTHS_PROXIMITY_THRESHOLD) {
                    promisesArr.push(requestAppointments(latestMonthLoadedDate, NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE, signal2));
                }
                if(promisesArr.length) {
                    const resolvedPromises = await Promise.all(promisesArr);
                    if(resolvedPromises.every(result => !result)) return;
                    const destructuredArray = {};
                    resolvedPromises.forEach((yearMonthObjects) => {
                        if(!yearMonthObjects) return
                        Object.keys(yearMonthObjects).forEach(yearMonthKey => destructuredArray[yearMonthKey] = yearMonthObjects[yearMonthKey]);
                    });

                    const cachedAppointmentsByMonth = await retrieveJsonCache('appointmentsByMonth') || {};
                    await storeJsonInCache('appointmentsByMonth', {...cachedAppointmentsByMonth, ...destructuredArray});

                    SetAppointmentsByMonth({...cachedAppointmentsByMonth, ...destructuredArray});
                }

                function calculateProximityInMonths(date1, date2) {
                    var months;
                    months = (date2.getFullYear() - date1.getFullYear()) * 12;
                    months -= date1.getMonth();
                    months += date2.getMonth();
                    return months;
                };

                async function requestAppointments(date, numberOfMonthsRequested, signal) {
                    const startDate = new Date(date);
                    const endDate = new Date(date);
                    const requestingMonthsInThePast = numberOfMonthsRequested < 0;
                    const keys = {};

                    //generate and update appointmentsByMonth to stop future request for the months we are already gonna be waiting a response for.
                    for(let i = 1; i <= NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE; i++) {
                        let value;
                        if(requestingMonthsInThePast) value = i * -1;
                        else value = i;
                        keys[yearMonthKeyGenerator(date, value)] = [];
                    }

                    if(requestingMonthsInThePast) {
                        startDate.setMonth(startDate.getMonth() + (NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE * -1));
                        startDate.setDate(1);
                        startDate.setHours(0, 0, 0, 0);
                        endDate.setMonth(endDate.getMonth() - 1);
                        endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
                        endDate.setHours(23, 59, 59, 999);
                    } else {
                        startDate.setMonth(startDate.getMonth() + 1);
                        startDate.setDate(1);
                        startDate.setHours(0, 0, 0, 0);
                        endDate.setMonth(endDate.getMonth() + NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE);
                        endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
                        endDate.setHours(23, 59, 59, 999);
                    };

                    const yearMonthPairsToBeRequested = [];

                    for(let i = 0; i < NUMBER_OF_MONTHS_TO_REQUEST_AHEAD_OR_BEFORE_OF_DATE; i++) {
                        const date1 = new Date(startDate);
                        date1.setMonth(date1.getMonth() + i);
                        const year1 = date1.getFullYear();
                        const month1 = date1.getMonth();
                        yearMonthPairsToBeRequested.push(`${year1}-${month1}`);
                    };

                    for(let i = 0; i < yearMonthPairsToBeRequested.length; i++) {
                        const dateKey = yearMonthPairsToBeRequested[i];
                        if(appointmentsByMonthRequestedMonths_ref.current.includes(dateKey)) startDate.setMonth(startDate.getMonth() + 1);
                        else {
                            appointmentsByMonthRequestedMonths_ref.current.push(...yearMonthPairsToBeRequested.splice(i, yearMonthPairsToBeRequested.length));
                            break;
                        }
                    };

                    // sort requested dates array
                    appointmentsByMonthRequestedMonths_ref.current = appointmentsByMonthRequestedMonths_ref.current.sort((a, b) => {
                        a = addZeroToDayIfLessThanTen(a);
                        b = addZeroToDayIfLessThanTen(b);

                        a = a.split('-');
                        b = b.split('-');

                        a = Number(`${a[0]}${a[1]}`);
                        b = Number(`${b[0]}${b[1]}`);

                        return a > b ? 1 : a < b ? -1 : 0;

                        function addZeroToDayIfLessThanTen(string) {
                            let [year, month] = string.split('-');
                            if(month < 10) month = '0' + month;
                            return `${year}-${month}`;
                        }
                    });

                    const startDateGreaterThanEndDate = startDate.getTime() > endDate.getTime();
                    if(startDateGreaterThanEndDate) return;

                    const response = await getMonthlyAppointments(startDate, endDate, signal);
                    
                    response.forEach(appointment => {
                        const dateObj = new Date(appointment.time);
                        const month = dateObj.getMonth();
                        const year = dateObj.getFullYear();
                        keys[`${year}-${month}`].push(appointment);
                    });

                    Object.keys(keys).forEach(key => {
                        const sorted = sortBy(keys[key], 'time');
                        keys[key] = sorted;
                    })

                    return keys;
                };

                function yearMonthKeyGenerator(date, value) {
                    const newDate = new Date(date);
                    newDate.setDate(1);
                    newDate.setMonth(newDate.getMonth() + value);
                    return `${newDate.getFullYear()}-${newDate.getMonth()}`
                };
            } catch (err) {
                if(!signal1.aborted && !signal2.aborted) console.error(err);
            };
        };

        loadAppointments();

        return () => {
            // controller1.abort();
            // controller2.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, appointmentsByMonth, appointmentsByMonthInitialLoad]);

    useEffect(() => {
        if(!navigator.serviceWorker) return;
        
        const handleServiceWorkerMessage = async e => {
            const { phoneNumber, message, appointmentId, index } = e.data;

            try {
                if(e.data.type === 'send-whatsapp-message') {
                    await markReminderAsSent(appointmentId, index);
                    fireWhatsapp(phoneNumber, message);
                } else if(e.data.type === 'alert') {
                    alert(e.data.message);
                }
            } catch (err) {
                console.error(err);
                if(e.data.type === 'send-whatsapp-message') {
                    fireWhatsapp(phoneNumber, message);
                }
            }
        }

        const initiateMyLocalSettings = () => {
            const localSettings = localStorage.getItem('localSettings') ? JSON.parse(localStorage.getItem('localSettings')) : {};
            const businessId = myData.business._id.toString();
            if(!localSettings[businessId]) localSettings[businessId] = {
                defaultReminders: DEFAULT_REMINDERS
            };
            const myBusinessLocalSettings = localSettings[businessId];
            if(!myBusinessLocalSettings[myData._id.toString()]) myBusinessLocalSettings[myData._id.toString()] = {
                ...DEFAULT_LOCAL_SETTINGS,
                myData: myData
            };
            localStorage.setItem('localSettings', JSON.stringify(localSettings));
        };
    
        if(myData) setTimeout(() => initiateMyLocalSettings(), 1000);
        navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

        return () => {
            navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myData]);

    /**
     * 
     * @param {MongooseObjectId} cacheName Can be retrieved from myData
     * @param {String} key Name to store it as
     * @param {any} jsonData Data that will be stored
     */
    const storeJsonInCache = async (key, jsonData) => {
        try {
            // Open the cache
            const myData = JSON.parse(localStorage.getItem('myData'));
            const businessId = myData.business._id.toString();
            const cacheName = businessId;
            const cache = await caches.open(cacheName);
    
            const currentData_response = await cache.match('/data.json');
            // Create a new Response object with JSON data
            let currentData = {};
            if(currentData_response) currentData = await currentData_response.json();

            const jsonResponse = new Response(JSON.stringify({...currentData, [key]: jsonData}), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            
            // Store the JSON response in the cache
            await cache.put('/data.json', jsonResponse);

        } catch (error) {
            console.error('Error storing JSON data in the cache:', error);
        }
    }

    const retrieveJsonCache = async (propertyName) => {
        try {
            // Open the cache
            const myData = JSON.parse(localStorage.getItem('myData'));
            const businessId = myData.business._id.toString();
            const cacheName = businessId;
            const cache = await caches.open(cacheName);
    
            // Retrieve the JSON response from the cache
            const response = await cache.match('/data.json');

            if (response) {
                // If the response is found in the cache, parse the JSON data
                const jsonData = await response.json();
                if(propertyName) return jsonData[propertyName] || {};
                return jsonData;
            } else {
                console.log('No JSON data found in the cache');
                return null;
            }
        } catch (error) {
            console.error('Error retrieving JSON data from the cache:', error);
        }
    }

    const isANumber = (value) => {
        return value !== '' && !isNaN(value);
    }

    useEffect(() => {
        window.addEventListener('appinstalled', () => {
            const isDev = window.location.hostname === 'localhost';
            const localStorageKey = isDev ? 'isAppInstalledDevelopment' : 'isAppInstalledProduction';
            localStorage.setItem(localStorageKey, "true");
            isAppInstalled.current = true;
            if(isMobileRef.current) return;
        });

        const handleOnlineStatusChange = () => {
            SetIsOnline(navigator.onLine);
        };

        const handleMatchMediaChange = (evt) => {
            let displayMode = 'browser';
            if (evt.matches) {
                displayMode = 'standalone';
            };
            if(displayMode === 'standalone') {
                isAppInstalled.current = true;
                navigate('/')
            };
        }

        const checkCachedReminder = async () => {
            try {
                const response = await fetch('/reminder.json'); // Assuming '/data.json' is the URL you used to store the object
                if(response.statusText === 'reminder') {
                    const {message, phoneNumber} = await response.json();
                    fireWhatsapp(phoneNumber, message)
                    await caches.delete('reminder');
                }
            } catch (err) {
                console.error('Error:', err);
            }
        }

        const handleBeforeUnload = (event) => {
            if(!changesMadeRef.current) return;
            event.preventDefault();
            const message = 'Are you sure you want to leave?';
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };

        const loadStoredAppointments = async () => {
            const myData = JSON.parse(localStorage.getItem('myData'));
            if(!myData) return;
            
            const cachedAppointmentsByMonth = await retrieveJsonCache('appointmentsByMonth') || {};
            SetAppointmentsByMonth(cachedAppointmentsByMonth);
        }

        loadStoredAppointments();
        checkCachedReminder();

        window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
        window.matchMedia('(display-mode: standalone)').addEventListener('change', handleMatchMediaChange);
        window.addEventListener('online', handleOnlineStatusChange);
        window.addEventListener('offline', handleOnlineStatusChange);

        return () => {
            window.matchMedia('(display-mode: standalone)').removeEventListener('change', handleMatchMediaChange);
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOnlineStatusChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const value = {
            showModal,
            jwt,
            serverAddress,
            loginEndPoints,
            patientsEndPoints,
            setToken,
            logout,
            redirect,
            resetModal,
            refreshTokenAPI,
            selectedPatient,
            SetSelectedPatient,
            staffEndPoints,
            businessSocketRoom,
            showContextMenu,
            SetShowContextMenu,
            contextMenuCoordinates,
            SetContextMenuCoordinates,
            contextMenuOptions,
            SetContextMenuOptions,
            productsEndpoints,
            servicesEndpoints,
            isANumber,
            TOAST_ANIMATION_TIME,
            TOAST_ANIMATION_TIME_MOBILE,
            showToast,
            fireToast,
            SetShowToast,
            testEndPoints,
            DEFAULT_PATIENT_TEMPLATE_VARIABLES,
            fetchData,
            getDoctors,
            isRunningInAppMode,
            checkUnsavedChangesBeforeContinue,
            showGlobalConfirmationModal,
            SetShowGlobalConfirmationModal,
            changesMadeRef,
            isMobileRef,
            businessName: businessUserName,
            showOptionsMenu,
            SetShowOptionsMenu,
            isAppInstalled,
            isOnline,
            myData,
            SetMyData,
            disableOverScroll,
            enableOverScroll,
            fireWhatsapp,
            markReminderAsSent,
            getMachineId,
            subscribeWebWorker,
            setReceiveReminderMessages,
            PUBLIC_URLS,
            DEFAULT_LOCAL_SETTINGS,
            appointmentsByMonth,
            SetAppointmentsByMonth,
            jwtRef,
            doctorsList,
            SetDoctorsList,
            selectedDate,
            SetSelectedDate,
            appointmentsByMonthInitialLoad,
            storeJsonInCache,
            retrieveJsonCache,
            isGlobalTransitionPending,
            SetIsGlobalTransitionPending,
            updateMyData,
            getQueryParameter,
            navigateTo,
            navigate,
            removeParams,
            DEFAULT_SUCCESS_MESSAGE,
            DEFAULT_ERROR_MESSAGE,
            location,
            modalWithAnimationsOptions,
            setModalWithAnimationsOptions: SetModalWithAnimationsOptions
        }
    // }, [jwt, showModal, settingsToggle, clinicalRecord, getClinicalRecord, SetClinicalRecord, updateClinicalRecord, setClinicalRecord])

    return <GlobalsContext.Provider value={value} {...props} />;
}

export function useGlobals() {
    const context = useContext(GlobalsContext);
    if(!context) {
        throw new Error('Redirect context must be inside of RedirectContext provider');
    }
    return context
}