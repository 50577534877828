const handleResponse = async (response) => {
        if (!response.ok) {
            const _response = await response.json();
            throw new Error(_response.message);
        }
        return response.json();
};

const networkInterceptor = async (url, options) => {
    // console.log(url)
    try {
        const response = await fetch(url, options);

        if(response.status !== 200) {
            const contentType = response.headers.get('content-type');
            if(contentType === 'text/html; charset=utf-8') {
                const { status, statusText, url } = response;
                throw new Error(`${status} ${statusText}: ${url}`);
            } else {
                const errorResponse = await response.json();
                let error;
                if(errorResponse.options) {
                    error = JSON.stringify({
                        message: errorResponse.message,
                        options: errorResponse.options
                    });
                } else error = errorResponse.message;
                throw new Error(error);
            }
            // const errorObj = {
            //     title: errorResponse.title,
            //     errors: errorResponse.errors
            // };
            // console.error(errorResponse.title)
            // console.error(errorResponse.errors)
        };
        return handleResponse(response);
    } catch (err) {
        throw new Error(err.message);
    }
};

export default networkInterceptor;