import React, { Suspense, lazy, useEffect, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { RedirectProvider } from "./context/globals-context";
import ModalWithAnimation from 'src/components/modalWithAnimation/ModalWithAnimation';

import { useGlobals } from "./context/globals-context";

import './global.scss';

const lazyWithPreload = (factory) => {
    const Component = lazy(factory)
    Component.preload = factory
    return Component
}

const Login = lazyWithPreload(() => import("./components/login/Login"));
const NavBar = lazyWithPreload(() => import("./components/navBar/NavBar"));
const NavBarMobile = lazyWithPreload(() => import("./components/navBar/NavbarMobile"));
// const MyAppointments = lazyWithPreload(() => import("./components/myAppointments/MyAppointments"));
const History = lazyWithPreload(() => import("./components/history/History"));
const Whatsapp = lazyWithPreload(() => import("./components/whatsapp/Whatsapp"));
const Patients = lazyWithPreload(() => import("./components/patients/Patients"));
const Modal = lazyWithPreload(() => import("./components/modal/Modal"));
const MenuContext = lazyWithPreload(() => import("./components/menuContext/MenuContext"));
const CalendarPage = lazyWithPreload(() => import("./components/calendarPage/CalendarPage"));
const ProductsAndServices = lazyWithPreload(() => import("./components/productsAndServices/ProductsAndServices"));
const Toast = lazyWithPreload(() => import("./components/toast/Toast"));
// const Home = lazyWithPreload(() => import("./components/home/Home"));
const OptionsMenu = lazyWithPreload(() => import("./components/optionsMenu/OptionsMenu"));
const Admin = lazyWithPreload(() => import("./components/admin/Admin"));
const NewPassword = lazyWithPreload(() => import("./components/newPassword/NewPassword"));
const Statistics = lazyWithPreload(() => import("./components/statistics/Statistics"));
const Reminders = lazyWithPreload(() => import("./components/reminders/Reminders"));
const Test = lazyWithPreload(() => import("./components/test/Test"));
const Settings = lazyWithPreload(() => import("./components/settings/Settings"));
const NewProfile = lazyWithPreload(() => import("./components/newProfile/NewProfile"));
const PatientsMobile = lazyWithPreload(() => import("./components/patients/PatientsMobile"));
const CashRegister = lazyWithPreload(() => import("./components/cashRegister/CashRegister"));

const PAGES_ARRAY = [
    Login,
    NavBar,
    NavBarMobile,
    // MyAppointments,
    History,
    Whatsapp,
    Patients,
    Modal,
    MenuContext,
    CalendarPage,
    ProductsAndServices,
    Toast,
    OptionsMenu,
    Admin,
    NewPassword,
    Statistics,
    Reminders,
    Test,
    Settings,
    NewProfile,
    PatientsMobile,
    CashRegister,

]

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <RedirectProvider>
        <Suspense>
            <App />
        </Suspense>
    </RedirectProvider>
);

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const {
        showModal,
        resetModal,
        showContextMenu,
        showToast,
        showGlobalConfirmationModal,
        SetShowGlobalConfirmationModal,
        isMobileRef,
        isOnline,
        PUBLIC_URLS,
        modalWithAnimationsOptions
    } = useGlobals();

    const disableGoBackOnClickTimeout_ref = useRef();
    const initialClickTarget_ref = useRef();

    useEffect(() => {
        const root = document.querySelector('#root');
        
        root.classList.add('ready');
        PAGES_ARRAY.forEach(page => {
            page.preload()
        });

        const assignInitialClickTarget = (e) => {
            initialClickTarget_ref.current = e.target;
        }

        const goBackOnClickingOutsideOfModal = (e) => {
            if(disableGoBackOnClickTimeout_ref.current) return;
            disableGoBackOnClickTimeout_ref.current = setTimeout(() => {
                disableGoBackOnClickTimeout_ref.current = null;
            }, 500);
            if(initialClickTarget_ref.current?.dataset.type === 'modal') navigate(-1);
        }

        window.addEventListener('mousedown', assignInitialClickTarget);
        window.addEventListener('touchstart', assignInitialClickTarget);
        window.addEventListener('touchEnd', goBackOnClickingOutsideOfModal);
        window.addEventListener('mouseup', goBackOnClickingOutsideOfModal);

        return () => {
            window.removeEventListener('mousedown', assignInitialClickTarget);
            window.removeEventListener('touchstart', assignInitialClickTarget);
            window.removeEventListener('touchEnd', goBackOnClickingOutsideOfModal);
            window.removeEventListener('mouseup', goBackOnClickingOutsideOfModal);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            { showContextMenu  ? <MenuContext /> : ''}
            {showModal && <Modal onClose={resetModal} title="Error de autenticación" text="Por favor, loguearse nuevamente" close="Loguearse" type="danger" />}
            {showGlobalConfirmationModal && <Modal
                onClose={showGlobalConfirmationModal.onClose}
                SetShowModal={SetShowGlobalConfirmationModal}
                title={showGlobalConfirmationModal.title}
                text={showGlobalConfirmationModal.text}
                close={showGlobalConfirmationModal.close}
                type={showGlobalConfirmationModal.type}
                acceptText={showGlobalConfirmationModal.acceptText}
                onAcceptProps={showGlobalConfirmationModal.onAcceptProps}
                onAccept={showGlobalConfirmationModal.onAccept}
                extraClasses={isMobileRef.current ? 'mobile' : ''}
            />}
            {showToast ? <Toast /> : ''}
            {isMobileRef.current ? <OptionsMenu /> : ''}
            <div className={`main-content ${isMobileRef.current ? 'mobile' : ''} show`} onContextMenu={e => e.preventDefault()}>
                <div className={`not-online__wrapper ${isOnline ? '' : 'show'}`}>
                    <div className="not-online-warning">
                        <img src='images/icons/logo.svg' alt="Logo"/>
                        <div className="flex-column">
                            <h3>Sin conexión</h3>
                            <span>Revisa tu conexión a internet</span>
                        </div>
                    </div>
                </div>
                { !PUBLIC_URLS.includes(location.pathname) && location.pathname && location.pathname !== '/' && !isMobileRef.current ? <NavBar /> : ''}
                <img src={isMobileRef.current ? 'images/mobile-bg.svg' : 'images/bg.jpg'} className={isMobileRef.current ? 'mobile-bg' : 'bg'} alt="Fondo de pantalla" />
                <Routes>
                    {/* <Route path="/home" exact element={<Home />} /> */}
                    <Route path="/new-password" element={<NewPassword />} />
                    <Route path="/" exact element={<Login />} />
                    <Route path="/admin" exact element={<Admin />} />
                    {/* <Route path="/my-appointments" element={<MyAppointments />} /> */}
                    <Route path="/medical-history" exact element={<History />} />
                    <Route path="/patients" exact element={<Patients />} />
                    <Route path="/patients-mobile" exact element={<PatientsMobile />} />
                    <Route path="/calendar-page" exact element={<CalendarPage />} />
                    <Route path="/products-and-services" exact element={<ProductsAndServices />} />
                    <Route path="/whatsapp" exact element={<Whatsapp />} />
                    <Route path="/offline" exact element={() => <h1>offline, no connection</h1>} />
                    <Route path="/statistics" exact element={<Statistics />} />
                    <Route path="/reminders" exact element={<Reminders />} />
                    <Route path="/settings" exact element={<Settings />} />
                    <Route path="/test" exact element={<Test />} />
                    <Route path="/new-profile" exact element={<NewProfile />} />
                    <Route path="/cash-register" exact element={<CashRegister />} />
                </Routes>
                {/* <div className="test-buttons__wrapper">
                    <button className="button--blue" onClick={e => handleTest(e)}>Send push notification</button>
                    <button className="button--blue"><a href="whatsapp://send?phone=51953239649&text=test">Open whatsapp prefilled message</a></button>
                </div> */}
                {/* {isGlobalTransitionPending && <div className="transitioning"></div>} */}
                <ModalWithAnimation {...modalWithAnimationsOptions} /> 
            </div>
            { !PUBLIC_URLS.includes(location.pathname)
                && location.pathname
                && location.pathname !== '/'
                && isMobileRef.current
                ? <NavBarMobile />
                :''}
        </React.Fragment>
    )
}