import { useState, useEffect } from 'react';

import { useGlobals } from 'src/context/globals-context';
import LoadingBars from '../loadingBars/LoadingBars';
import './ModalWithAnimations.scss';

const ModalWithAnimation = ({
    pWidth,
    onClose,
    closeButtonText = 'Cancelar',
    text,
    title = '',
    onAccept,
    acceptText = 'Aceptar',
    onAcceptProps,
    type = 'success',
    extraClasses = '',
    loading,
    queryParamName,
    queryParamValue
}) => {

    const ANIMATION_DURATION = 200;

    const { getQueryParameter, navigate } = useGlobals();

    const [ loadModal, SetLoadModal ] = useState(false);
    const [ showModal, SetShowModal ] = useState(false);

    const showModal_param = getQueryParameter(queryParamName) === queryParamValue;

    useEffect(() => {
        if(showModal_param) {
            SetLoadModal(true);
            setTimeout(() => SetShowModal(true), 100);
        } else {
            SetShowModal(false);
            setTimeout(() => SetLoadModal(false), ANIMATION_DURATION);
        };
    }, [showModal_param]);
    
    return (
        loadModal ? (
            <div className={`modal-with-animations ${extraClasses} ${showModal ? 'show-modal' : ''}`} data-type="modal" style={{'--animation-duration': `${ANIMATION_DURATION / 1000 }s`}}>
                <div className="container">
                    <img src={
                        type === 'success' ? 'images/icons/success.svg'
                        : type === 'info' ? 'images/icons/info.svg'
                        : type === 'warning' ? 'images/icons/warning.svg'
                        : 'images/icons/danger.svg'
                    } alt="Éxito" />
                    {/* <h1 className={
                            type === 'success' ? 'text-color--green'
                            : type === 'info' ? 'text-color--gray'
                            : type === 'warning' ? 'text-color--yellow'
                            : 'text-color--red'
                        }>{title}</h1> */}
                    <h1>{title}</h1>
                    <p style={{"--p-width": pWidth}}>{text}</p>
                    <div className={`buttons--wrapper ${loading ? 'disable-element' : ''}`}>
                        <button className={`button--cancel ${
                            type === 'success' ? 'button--green'
                            : type === 'info' ? 'button--blue'
                            : type === 'warning' ? 'button--yellow'
                            : 'button--red'
                        }`} onClick={e => onClose ? onClose() : navigate(-1)}>{closeButtonText}</button>
                        { onAccept 
                            ? <button className={
                                `
                                ${loading ? 'disable-element' : ''}
                                ${
                                    type === 'success' ? 'button--green'
                                    : type === 'info' ? 'button--blue'
                                    : type === 'warning' ? 'button--yellow'
                                    : 'button--red'}
                                `
                        } onClick={e => {
                                onAccept(onAcceptProps);
                                navigate(-1);
                            }}>{acceptText} {loading ? <LoadingBars containerClasses='tiny white-bars' cssVariables={{position: 'absolute', left: '43%'}} /> : ''}</button>
                            : ''
                        }
                    </div>
                </div>
            </div>
        ) : ''
    )
};

export default ModalWithAnimation;
