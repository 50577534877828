import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {HashRouter} from 'react-router-dom'

import App from './App';

const root = document.getElementById('root');
const ReactRoot = ReactDOM.createRoot(root);

ReactRoot.render(
        <HashRouter>
                {/* <img src={ bg } className='bg' alt="background image" />*/}
                <App />
        </HashRouter>
);