// import { useGlobals } from "src/context/globals-context"

const useFormattingFunctions = () => {

    // const { doctors } = useGlobals();

    const capitalizeFirstLetter = (word) => {
        const firstLetter = word.charAt(0);
        const firstLetterCapitalized = firstLetter.toUpperCase();
        const restOfWord = word.slice(1);
        return firstLetterCapitalized + restOfWord;
    }

    const capitalizeFirstLetters = (words) => {
        if(!words) return '';
        const wordsArr = words.split(' ');
        return wordsArr.reduce((acc, word, index) => {
            const firstLetter = word.charAt(0);
            const firstLetterCapitalized = firstLetter.toUpperCase();
            const restOfWord = word.slice(1);
            if(wordsArr.length - 1 === index) return acc + firstLetterCapitalized + restOfWord;
            else return acc + firstLetterCapitalized + restOfWord + ' ';
        }, '');
    }

    const getTreatmentsProgress = (treatment) => {
        return treatment.reduce((acc, treatment) => treatment.finished ? ++acc : acc,0);
    }

    const getTreatmentsTotalCost = (treatments) => {
        return treatments.reduce((acc, treatment) => {
            return acc + treatment.cost - treatment.discount
        }, 0)
    }

    const getTreatmentsTotalDiscounts = (treatments) => {
        return treatments.reduce((acc, treatment) => {
            return acc + treatment.discount
        }, 0)
    }

    const getPaidAmount = (treatments) => {
        return treatments.reduce((acc, treatment) => {
            return acc + treatment.procedures.reduce((_acc, procedure) => {
                if(procedure._type === 'payment') return _acc + Number(procedure.amountPaid);
                else return _acc;
            }, 0);
        }, 0)
    }

    const getTreatmentsBalance = (payments, total) => {
        return payments.reduce((acc, payment) => {
            return acc - payment.amount
        }, total)
    }

    const getPaymentsPaidAmount = (payments) => {
        return payments.reduce((acc, payment) => {
            return acc + payment.amount;
        }, 0)
    }
    
    const millisecondsToTime = (milliseconds) => {
        const [hours, millisecondsRemainder] = getHours(milliseconds);
        const minutes = getMinutes(millisecondsRemainder);
        return `${hours || ''}${!hours ? '' : hours === 1 ? ' hora' : ' horas'}${minutes ? `${minutes}${minutes === 1 ? ' minuto': ' minutos'}` : ''}`

        function getHours(milliseconds) {
            let hours = 0;
            while(milliseconds >= 3600000) {
                ++hours;
                milliseconds-=3600000
            }
            return [hours, milliseconds];
        }
        function getMinutes(milliseconds) {
            let minutes = 0;
            while(milliseconds >= 60000) {
                ++minutes;
                milliseconds-=60000
            }
            return minutes;
        }
    }

    const formatJSDateObjToHoursAmPm = (dateObj, format) => {
        if(typeof dateObj !== 'object') dateObj = new Date(dateObj);

        const hours = dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours();
        const minutes = dateObj.getMinutes() < 10 ? '0' + dateObj.getMinutes() : dateObj.getMinutes();
        const amPm = dateObj.getHours() < 12 ? 'am' : 'pm';

        if(format === 'afternoon-morning') {
            return `${hours}:${minutes} ${amPm === 'am' ? 'de la mañana' : 'de la tarde'}`;
        } else return `${hours}:${minutes}${amPm}`;
    }

    const formatDate = (date, format, reformat) => {
        const months = [
            'enero',
            'febrero',
            'marzo',
            'abril',
            'mayo',
            'junio',
            'julio',
            'agosto',
            'septiembre',
            'octubre',
            'noviembre',
            'diciembre'
        ]
        const dayName = [
            'Domingo',
            'Lunes',
            'Martes',
            'Miércoles',
            'Jueves',
            'Viernes',
            'Sábado'
        ]
        if(typeof date === 'string' && reformat) date = date.replaceAll('-', '/');
        if(typeof date !== 'object') date = new Date(date);
        if(typeof date !== 'object') return 'Error con la fecha';

        const dayOfTheWeek = date.getDay();
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        
        if(format === 'written-out') {
            return `${day} de ${months[month]}, ${year}`           
        } else if(format === 'date-and-time') {
            return `${day}-${month + 1}-${year} ${formatJSDateObjToHoursAmPm(date)}`
        } else if(format === 'written-out-and-time') {
            return `${day} de ${months[month]}, ${year} ${formatJSDateObjToHoursAmPm(date)}`
        } else if(format === 'time-date') {
            return `${formatJSDateObjToHoursAmPm(date)} - ${day}/${month + 1}/${year}`
        } else if(format === 'day-month') {
            return `${day} ${months[month]}`;
        } else if(format === 'day-and-month') {
            return `${day} de ${months[month]}`;
        } else if(format === 'month year') {
            return `${months[month]} ${year}`;
        } else if(format === 'Month year') {
            return `${capitalizeFirstLetter(months[month])} ${year}`;
        } else if(format === 'week-day-and-date') {
            return `${dayName[dayOfTheWeek]} ${day} de ${months[month]}`
        } else if(format === 'dashed') {
            return `${year}-${month + 1}/${day}`
        } else {
            return `${day}/${month + 1}/${year}`
        }
    }

    const addComas = numberString => {
        numberString = numberString.toString();
        let negativeSign = '';
        if(numberString[0] === '-') {
            negativeSign = numberString.split('').splice(0, 1).join('')
            numberString = numberString.substring(1);
        };
        const arr = numberString.split('.')
        if(arr[0].length > 3) {
            const position = arr[0].length - 3;
            const newArr = arr[0].split('')
            newArr.splice(position, 0, ',')
            const numberWithComma = newArr.join('');
            if(arr[1]) return numberWithComma + '.' + arr[1];
            else return numberWithComma;
        } else return negativeSign + numberString;
    }

    const formatCentsWithCommas = (number) => {        
        if(!number) return '0.00';
        number = number.toString().split('').filter(character => character !== ',').join('');
        
        const numberString = Number.parseFloat(number).toFixed(2);

        return addComas(numberString);
    }

    const formatNumbersWithCommas = (number) => {
        
        if(!number) return '0';
        const numberString = Math.round(Number(number));

        return addComas(numberString);
    }

    const formatCents = (number) => {
        if(!number) return '0.00';
        return Number.parseFloat(number).toFixed(2);
    }

    // const formatDoctorIdToDoctorName = doctorId => {
    //     return doctors.find(doctor => doctor._id === doctorId)?.fullName;
    // }

    const formatDateAndTimeToJSDateString = ({year, month, day, hours, minutes, amPm}) => {
        const formatWithZero = number => {
            return number < 10 ? '0' + number : number
        }
        return `${year}-${formatWithZero(month + 1)}-${formatWithZero(day)}T${formatWithZero(amPm === 'pm' && hours < 12 ? hours + 12 : hours )}:${formatWithZero(minutes)}:00`;
    }

    const get12HourHoursFromDate = (date) => {
        if(typeof date !== 'object') date = new Date(date);
        const unformattedHours = date.getHours();
        const hours = unformattedHours > 12 ? unformattedHours - 12 : unformattedHours;
        return hours
    }

    const formatJSDateStringToHoursAmPm = date => {
        const dateObj = new Date(date);
        const hours = dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours();
        const minutes = dateObj.getMinutes() < 10 ? '0' + dateObj.getMinutes() : dateObj.getMinutes();
        const amPm = dateObj.getHours() < 12 ? 'am' : 'pm';

        return `${hours}:${minutes} ${amPm}`;
    }

    const formatMonth = date => {
        const monthKeysValuePairs = {
            0: 'Enero',
            1: 'Febrero',
            2: 'Marzo',
            3: 'Abril',
            4: 'Mayo',
            5: 'Junio',
            6: 'Julio',
            7: 'Agosto',
            8: 'Septiembre',
            9: 'Octubre',
            10: 'Noviembre',
            11: 'Diciembre'
        };

        return typeof date === 'object' ? monthKeysValuePairs[date.getMonth()] : monthKeysValuePairs[date];
    }

    const formatDateToYYYYMMDD = (date, format) => {
        if(!date) return '';
        if(typeof date !== 'object') {
            if(date.includes('-') && date.length === 10) date = new Date(date.replaceAll('-', '/'));
            else date = new Date(date)
        };
        const year = date.getFullYear();
        const monthOneBasedIndex = date.getMonth() + 1;
        const month = monthOneBasedIndex < 10 ? '0' + monthOneBasedIndex : monthOneBasedIndex;
        const unformattedDay = date.getDate();
        const day = unformattedDay < 10 ? '0' + unformattedDay : unformattedDay;
        if(format === 'dash') {
            return `${year}-${month}-${day}`;
        }
        return `${year}/${month}/${day}`;
    }

    function formatAge(date) {
        if(!date) return 0;
        if(typeof date !== 'object') date = new Date(date);
        var today = new Date();
        var birthDate = new Date(date);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const formatTimeStamp = (timeStamp, type) => {
        let amPm = 'am'
        if(!timeStamp) return
        const date = new Date(timeStamp);
        let hours = date.getHours();
        let _hours = hours;
        let minutes = date.getMinutes();
        let _minutes = minutes;
        if(minutes < 10) _minutes = `0${minutes}`;
        if(type === 24) {
            return `${_hours}:${_minutes}`;
        }
        if(hours < 10) _hours = `0${hours}`;
        else if(hours > 12) {
            _hours -= 12;
        } 
        if(hours >= 12) amPm = 'pm';
        if(type === 'no-space') return `${_hours}:${_minutes}${amPm}`;
        return `${_hours}:${_minutes} ${amPm}`;
    }

    const formatTeethNumbers = (string) => {
        if(!string) return ['', false];

        const ACCEPTED_TEETH_NUMBERS = [
            1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1,
            2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8,
            4.8, 4.7, 4.6, 4.5, 4.4, 4.3, 4.2, 4.1,
            3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8,
            5.5, 5.4, 5.3, 5.2, 5.1,
            6.1, 6.2, 6.3, 6.4, 6.5,
            8.5, 8.4, 8.3, 8.2, 8.1,
            7.1, 7.2, 7.3, 7.4, 7.5,
            18, 17, 16, 15, 14, 13, 12, 11,
            21, 22, 23, 24, 25, 26, 27, 28,
            48, 47, 46, 45, 44, 43, 42, 41,
            31, 32, 33, 34, 35, 36, 37, 38,
            55, 54, 53, 52, 51,
            61, 62, 63, 64, 65,
            85, 84, 83, 82, 81,
            71, 72, 73, 74, 75,
        ]

        return formatCommaSeparatedValues(string);

        function formatCommaSeparatedValues (string) {
            if(!string) return ['', false];
            const stringNoSpaces = string.replaceAll(' ', ',');
            const teethArr = stringNoSpaces.split(',').filter(current => current !== '');
            let foundWrongValues = false;
            const correctValues = teethArr.reduce((acc, currentValue) => {
                const currentToothNumber = Number(currentValue);
                const isToothNumber = ACCEPTED_TEETH_NUMBERS.includes(Number(currentToothNumber));
                if(isToothNumber) {
                    if(currentToothNumber >= 11) return [...acc, Number(currentToothNumber.toString().split('').join('.'))];
                    return [...acc, currentToothNumber]
                } else {
                    foundWrongValues = true;
                    return acc;
                }
            }, []);

            return [correctValues.join(', '), foundWrongValues];
        }
    }

    const isSameDate = (date1, date2) => {
        if(typeof date1 !== 'object') date1 = new Date(date1);
        if(typeof date2 !== 'object') date2 = new Date(date2);

        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear() 
        )
    }

    const isTomorrowsDate = (date1, date2) => {
        date1 = new Date(date1);
        date2 = new Date(date2);
        date1.setDate(date1.getDate() + 1);

        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear() 
        )
    }
    
    const isAfterTomorrowsDate = (date1, date2) => {
        date1 = new Date(date1);
        date2 = new Date(date2);
        date1.setDate(date1.getDate() + 2);

        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear() 
        )
    }

    const formatDayNameAndNumberTomorrowAfterTomorrow = (appointmentDate, reminderDate) => {
        const _appointmentDate = new Date(appointmentDate);
        const _remindersDate = new Date(reminderDate);

        if(isSameDate(_remindersDate, _appointmentDate)) return 'hoy'
        else if(isTomorrowsDate(_remindersDate, _appointmentDate)) return 'mañana'
        else if(isAfterTomorrowsDate(_remindersDate, _appointmentDate)) return 'pasado mañana'
        const dayNumberToString = {
            0: 'Domingo',
            1: 'Lunes',
            2: 'Martes',
            3: 'Miércoles',
            4: 'Jueves',
            5: 'Viernes',
            6: 'Sábado'
        }

        const dateObj = new Date(appointmentDate);
        const dayName = dayNumberToString[dateObj.getDay()];
        const dayNumber = dateObj.getDate();

        return `el ${dayName} ${dayNumber}`.toLowerCase();
    }

    const dayName = (date, format) => {
        const _date = new Date(date);
        const dayNumberToString = {
            0: 'Domingo',
            1: 'Lunes',
            2: 'Martes',
            3: 'Miércoles',
            4: 'Jueves',
            5: 'Viernes',
            6: 'Sábado'
        }

        if(format === 'short') {
            return dayNumberToString[_date.getDay()].slice(0, 3);
        } else return dayNumberToString[_date.getDay()];
    }

    const numberToDayOfTheWeek = (number) => {
        const DAYS_NAMES = {
            0: 'domingo',
            1: 'lunes',
            2: 'martes',
            3: 'miércoles',
            4: 'jueves',
            5: 'viernes',
            6: 'sábado',
        };

        return DAYS_NAMES[number];
    }

    const formatYYYYMMDDLocalToUTC = (YYYYMMDD) => {
        const minutesOffset = new Date().getTimezoneOffset();
        const hoursOffset = minutesOffset / 60;
        const localeTimeObject = new Date(`${YYYYMMDD}T00:00:00${hoursOffset * -1 < 0 ? '-' : '+'}${Math.abs(hoursOffset) < 10 ? '0' + Math.abs(hoursOffset) : Math.abs(hoursOffset)}:00`);
        return localeTimeObject;
    }

    const getOldestProcedureFinishedOnDateMilliseconds = (treatments) => {
        const oldestDate = treatments.reduce((acc, treatment) => {
            const treatmentsProcedureOldestInMilliseconds = treatment.procedures.reduce((_acc, procedure) => {
                if(procedure.finishedOn) {
                    const finishedOnMilliseconds = new Date(procedure.finishedOn).getTime();
                    if(finishedOnMilliseconds > _acc) _acc = finishedOnMilliseconds;
                };
                return _acc;
            }, 0);
            if(treatmentsProcedureOldestInMilliseconds > acc) acc = treatmentsProcedureOldestInMilliseconds;
            return acc;
        }, 0);
        return oldestDate
    }

    function monthsApart(date1, date2) {
        const year1 = date1.getFullYear();
        const month1 = date1.getMonth() + 1; // Adding 1 to get the actual month
    
        const year2 = date2.getFullYear();
        const month2 = date2.getMonth() + 1;
    
        const yearDiff = year2 - year1;
        const monthDiff = yearDiff * 12 + month2 - month1;
    
        return monthDiff;
    }

    const getMonthsSinceLastPerformedProcedure = (treatments) => {
        const oldestProcedureFinishedOnDateInMilliseconds = getOldestProcedureFinishedOnDateMilliseconds(treatments);
        if(!oldestProcedureFinishedOnDateInMilliseconds) return false;
        const oldestProcedureDate = new Date(oldestProcedureFinishedOnDateInMilliseconds);
        
        const result = monthsApart(oldestProcedureDate, new Date());
        return result;
    }

    const translateAndJoinStaffRoles = (roles) => {
        const ROLES_TRANSLATION = {
            'admin': 'administrador',
            'doctor': 'dentista',
            'assistant': 'asistente',
            'receptionist': 'recepcionista'
        };

        return capitalizeFirstLetter(roles.map(role => ROLES_TRANSLATION[role]).join(', '));
    }

    const calculateAppointmentPosition = (scrollableElementHeight, containerInitialOffset, appointmentStartHour, appointmentStartMinutes, initialHours) => {
        const minutes = appointmentStartMinutes ? scrollableElementHeight / 2 : 0;
        
        return containerInitialOffset + (appointmentStartHour - initialHours) * scrollableElementHeight + minutes + scrollableElementHeight / 2;
    }

    function sortBy(arr, key) {
        const compareAscending = (a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
            return 1;
            }
            return 0;
        }

        return arr.sort((a, b) => {
            const propertyA = new Date(a[key]).getTime();
            const propertyB = new Date(b[key]).getTime();
            return compareAscending(propertyA, propertyB);
        })
    }

    const formatBytes = (bytes) => {
        if(typeof Number(bytes) !== 'number') return null;
        bytes = Number(bytes);
        bytes = Math.floor(bytes);
        const kilobytes = bytes / 1024
        const addComas = numberString => {
            const arr = numberString.split('.')
            if(arr[0].length > 3) {
                const position = arr[0].length - 3;
                const newArr = arr[0].split('')
                newArr.splice(position, 0, ',')
                const numberWithComma = newArr.join('');
                return numberWithComma;
            } else return numberString;
        }
    
        if (kilobytes >= 1) { // Convert to megabytes
            const megabytes = kilobytes / 1024;
            const gigs = megabytes / 1024;
            if(gigs >= 1) return `${addComas(gigs.toFixed(2))}GB`;
            else if(megabytes >= 1) return `${addComas(megabytes.toFixed(2))}MB`;
            return `${addComas(kilobytes.toFixed(2))}KB`;
        } else {
            return `${addComas(bytes.toFixed(2))}B`;
        }
    }

    function isPastelColor(hexColor) {
        // Convert hex to RGB
        let r = parseInt(hexColor.substring(1, 3), 16) / 255;
        let g = parseInt(hexColor.substring(3, 5), 16) / 255;
        let b = parseInt(hexColor.substring(5, 7), 16) / 255;
    
        // Convert to HSL
        let cmax = Math.max(r, g, b);
        let cmin = Math.min(r, g, b);
        let delta = cmax - cmin;
    
        // let h, s, l;
        let h, l;
    
        if (delta === 0) {
            h = 0;
        } else if (cmax === r) {
            h = ((g - b) / delta) % 6;
        } else if (cmax === g) {
            h = (b - r) / delta + 2;
        } else {
            h = (r - g) / delta + 4;
        }
    
        h = Math.round(h * 60);
    
        if (h < 0) {
            h += 360;
        }
    
        l = (cmax + cmin) / 2;
        // s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

        // Define pastel criteria
        let pastelLightnessThreshold = 0.5;
        // let pastelSaturationThreshold = 0.5;
    
        // Check if it's pastel
        return (l > pastelLightnessThreshold);
    }

    const standardizeString = string => {
        let standardizedString = string
        .toLowerCase()
        .trim();

        standardizedString = standardizedString.split(' ').filter(char => char);
        return standardizedString.join(' ');
    };

    const sortObjectByNumericValues = (obj) => {
        const keyValuePairs = Object.entries(obj);

        return keyValuePairs.sort((a, b) => b[1] - a[1]);
    };

    return {
        capitalizeFirstLetter,
        formatDate,
        formatAge,
        formatJSDateStringToHoursAmPm,
        // formatDoctorIdToDoctorName,
        formatDateAndTimeToJSDateString,
        formatMonth,
        formatCents,
        formatJSDateObjToHoursAmPm,
        get12HourHoursFromDate,
        formatDateToYYYYMMDD,
        formatTimeStamp,
        millisecondsToTime,
        getTreatmentsProgress,
        capitalizeFirstLetters,
        getTreatmentsTotalCost,
        getTreatmentsTotalDiscounts,
        getPaidAmount,
        getTreatmentsBalance,
        getPaymentsPaidAmount,
        formatTeethNumbers,
        formatDayNameAndNumberTomorrowAfterTomorrow,
        dayName,
        formatYYYYMMDDLocalToUTC,
        getMonthsSinceLastPerformedProcedure,
        formatCentsWithCommas,
        translateAndJoinStaffRoles,
        calculateAppointmentPosition,
        sortBy,
        isSameDate,
        formatBytes,
        isPastelColor,
        standardizeString,
        sortObjectByNumericValues,
        numberToDayOfTheWeek,
        formatNumbersWithCommas,
    }
}

export default useFormattingFunctions;