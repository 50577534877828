import './LoadingBars.scss';

const LoadingBars = ({classes = '', containerClasses = '', cssVariables = {}}) => {
    return (
        <div className={`loading-bars-container ${containerClasses}`} style={cssVariables}>
            <div className={`bars__wrapper ${classes ? classes : ''}`}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default LoadingBars;